@import url(https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&family=Oregano&display=swap);
/* Scrollbar styles */
.scrollbar-container {
  position: relative;
  height: 100%;
}

.ps {
  overflow: hidden;
  touch-action: auto;
}

.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  height: 15px;
  bottom: 0px;
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  width: 15px;
  right: 0;
  position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}

.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, height .2s ease-in-out;
  height: 6px;
  bottom: 2px;
  position: absolute;
}

.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
  width: 6px;
  right: 2px;
  position: absolute;
}

.ql-picker.ql-font .ql-picker-label[data-value="DM-Sans"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="DM-Sans"]::before {
  font-family: "DM Sans", sans-serif;
  content: "DM-Sans" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Inter"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Inter"]::before {
  font-family: "Inter", sans-serif;
  content: "Inter" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Oregano"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Oregano"]::before {
  font-family: "Oregano", cursive;
  content: "Oregano" !important;
}

/* Set content font-families */
.ql-font-DM-Sans {
  font-family: "DM Sans", sans-serif;
}
.ql-font-Inter {
  font-family: "Inter", sans-serif;
}
.ql-font-Oregano {
  font-family: "Oregano", cursive;
}
.ql-editor {
  white-space: normal !important;
}

