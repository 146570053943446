@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Inter&family=Oregano&display=swap");

.ql-picker.ql-font .ql-picker-label[data-value="DM-Sans"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="DM-Sans"]::before {
  font-family: "DM Sans", sans-serif;
  content: "DM-Sans" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Inter"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Inter"]::before {
  font-family: "Inter", sans-serif;
  content: "Inter" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Oregano"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Oregano"]::before {
  font-family: "Oregano", cursive;
  content: "Oregano" !important;
}

/* Set content font-families */
.ql-font-DM-Sans {
  font-family: "DM Sans", sans-serif;
}
.ql-font-Inter {
  font-family: "Inter", sans-serif;
}
.ql-font-Oregano {
  font-family: "Oregano", cursive;
}
.ql-editor {
  white-space: normal !important;
}
